.pat-pricing-form {
  .pat-pricing-text {
    white-space: pre-wrap;
  }

  .pat-pricing-button {
    margin-bottom: 10px;
  }

  .pat-pricing-delete-button {
    padding-right: 25px;
  }

  .pat-pricing-edit-cell {
    padding-left: 25px;
  }
}
