@import 'colors.css';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: var(--text-color);
}

.app {
  min-height: 100%;
  display: grid;
  grid-template-rows: 56px 1fr 56px;
}

a {
  color: var(--flexe-blue);
  text-decoration: none;
  &:hover {
    color: var(--flexe-blue);
  }
}

p {
  font-weight: 400;
  line-height: 1;
}

dl {
  & dt {
    color: var(--gray-darker);
    font-weight: 500;
    float: left;
    width: 50%;
    margin: 0 0 0.5rem;
  }
  & dd {
    float: left;
    width: 50%;
    margin: 0 0 0.5rem;
  }
}

.pure-g [class*='pure-u'] {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0 0 1em;
}

h1,
.h1 {
  font-size: 36px;
  font-weight: bold;
}

h2,
.h2 {
  font-size: 30px;
  font-weight: 300;
}

h3,
.h3 {
  font-size: 24px;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 18px;
  font-weight: 300;
}

h5,
.h5 {
  font-size: 16px;
  font-weight: 900;
}

h6,
.h6 {
  font-size: 12px;
  font-weight: 900;
}

.bread-crumbs {
  margin-bottom: 40px;
}

.error {
  color: var(--error-red);
}

.positive {
  color: var(--success-green);
}

.negative {
  color: var(--error-red);
}

/* Forms */
fieldset {
  background: #fcfcfc;
  border: 3px solid #f6f6f6;
  border-radius: 4px;
  margin: 0 0 2rem;
  padding: 1rem 1.5rem 1.5rem;
  & legend {
    color: var(--gray-med-dark);
    font-size: 18px;
    padding: 0 0.5rem;
  }
}

form .note {
  color: var(--gray-medium);
}

.checkbox {
  margin-top: 1rem;
}

/* Removes red box shadow on invalid form elements in Firefox */
:invalid {
  box-shadow: none;
}
/* /Forms */

.tool-bar {
  color: var(--white);
  & .ops-dashboard {
    font-size: 1.4em;
    margin: 0 0 0 36px;
    & a {
      color: var(--white);
      text-decoration: none;
    }
  }
  & .toolbar-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.toolbar-group-first {
      margin-left: -24px;
    }
  }
  & .nav_new {
    margin: 0 0 0 0px;
    & a {
      color: var(--white);
      font-family: Roboto, sans-serif;
      text-decoration: none;
      display: inline-block;
      padding: 0.6rem 0.75rem;
      margin: 0 0.4rem;
      border-radius: 3px;
      &:hover {
        background: var(--gray-med-dark);
      }
    }
  }
  & nav {
    margin-left: 24px;
    & a {
      color: var(--white);
      font-family: Roboto, sans-serif;
      text-decoration: none;
      display: inline-block;
      padding: 0.6rem 0.75rem;
      margin: 0 0.4rem;
      border-radius: 3px;
      &:hover {
        background: var(--gray-med-dark);
      }
      &.active {
        background: var(--gray-darker);
      }
    }
  }
}

.page {
  padding: 12px 36px 24px;
}

.form-button {
  margin-top: 2rem;
}

.pricing-tabs {
  & .tab-label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    & small {
      display: block;
    }
  }
}

td pre {
  white-space: pre-wrap;
}

td code {
  display: inline-block;
}

table.striped {
  & th.icon {
    padding: 0 1em;
  }
  & tr:nth-child(odd) td {
    background: var(--gray-lightest);
  }
}

.form-table {
  & th,
  & td {
    text-align: left;
    padding: 0 8px;
    height: 50px;
    border-bottom: 1px solid var(--gray-light);
  }
  & th {
    color: var(--gray-med-light);
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 8px;
  }
  & td:first-child {
    width: 40%;
  }
}

.sort-link {
  & span {
    display: inline-block;
    vertical-align: middle;
  }
  & svg {
    vertical-align: middle;
  }
}

.side-bar {
  padding-right: 24px;
  & fieldset {
    background: none;
    border: none;
    width: 100%;
    padding: 0;
    margin: 75px 0 0 0;
  }
}

.filters {
  list-style: none;
  padding: 0;
  margin: 0;
  & li {
    margin-top: 16px;
  }
}

footer {
  color: var(--gray-med-light);
  background-color: var(--gray-dark);
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .pipe {
    margin: 0 0.25rem;
  }
  & a {
    color: var(--gray-med-light);
  }
  & .copyright {
    font-size: 0.8rem;
  }
  & .logo {
    width: 60px;
    margin-left: 12px;
  }
}

.not-found-page {
  text-align: center;
  & .home-link {
    font-size: 1.2rem;
    text-decoration: underline;
    display: block;
  }
  & img {
    display: block;
    margin: 1rem auto;
  }
}

.material-icons.disabled {
  color: var(--gray-medium);
}
