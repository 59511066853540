@import '../../css/colors.css';

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);

  & .content {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  & .message {
    color: var(--white);
    padding: 20px;
    & a {
      color: var(--white);
      text-decoration: underline;
    }
  }

  &.overlay-exit {
    opacity: 1;
  }
  &.overlay-exit.overlay-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
  &.overlay-exited {
    display: none;
  }
}
