@import '../../../css/colors.css';

:root {
  --bold-header: {
    color: var(--gray-darker);
    font-size: 14px;
    font-weight: 600;
  };
}

table.pricing-table {
  border: none;
  & th {
    color: var(--gray-med-dark);
    font-size: 12px;
    font-weight: 400;
    position: relative;
  }
  & thead {
    &.packaging-types {
      background: none;
      & th {
        @apply --bold-header;
      }
    }
    &.company-types {
      background-color: var(--gray-lighter);
      & th:first-child {
        @apply --bold-header;
      }
    }
  }
}

.expense-types {
  background: #e0e0e0;
}
