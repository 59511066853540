@import '../../css/colors.css';

.new-reservation-page {
  & h2 {
    margin: 0 0 1rem;
  }
  & .nav-buttons {
    padding: 1.4rem 0;
    border-top: 1px solid var(--gray-lighter);
    margin-top: 80px;
    & > div {
      margin-right: 1rem;
    }
  }
  & .pricing-details-wrapper {
    max-width: 500px;
  }
  & .pricing-details {
    background-color: var(--gray-lightest);
    border: 1px solid var(--gray-lighter);
    border-radius: 8px;
    padding: 20px;
    margin-right: 40px;

    & h4 {
      color: var(--flexe-blue);
      font-size: 24px;
      font-weight: 300;
      margin: 3rem 0 1rem;
    }

    & ul {
      margin: 0 0 3rem;
    }

    & li {
      margin-bottom: 0.4rem;
      & span {
        font-weight: 100;
        width: 145px;
        display: inline-block;
      }
      & .fee-amount {
        text-align: right;
        width: 60px;
        display: inline-block;
      }
      & span.fee-amount {
        font-weight: 400;
      }
    }
  }
}
