@import '../../css/colors.css';

.adjust-sku-quantity-popover {
  & button[disabled] {
    opacity: 0.5;
  }
}

.no-locations {
  clear: both;
}

.locations-table {
  width: 100%;
  margin-bottom: 32px;
  border: none;

  & tbody {
    border-top: 1px solid var(--gray-lighter);
    &.default-location {
      background-color: var(--gray-lightest);
      border-color: var(--white);
      border-width: 2px;
      & td:first-child,
      & td:first-child + td {
        font-weight: 700;
      }
    }
    &.first-non-default-location {
      border-top: 1px solid var(--gray-medium);
    }
  }

  & .disabled {
    & td {
      color: var(--gray-med-light);
    }
    & td:first-child,
    & td:first-child + td {
      color: var(--text-color);
    }
  }

  & .edited {
    & td {
      background-color: var(--gray-lightest);
    }
  }

  & .editing {
    & td {
      height: 83px;
      background-color: var(--gray-lighter);
    }
  }

  & .update-error {
    & td {
      background-color: var(--red-highlight);
    }
  }

  & th {
    color: var(--gray-darkest);
    background: var(--white);
    border: none;
  }
  & td {
    border: none;
    height: 63px;
    transition: all 0.5s;
  }

  & .reservation-id {
    width: 15%;
  }
  & .adjustment {
    color: var(--gray-med-dark);
    width: 15%;
    & .conversion {
      display: block;
    }
  }
  & th.issue-type svg {
    position: relative;
    top: 6px;
  }
  & td.issue-type {
    font-weight: 700;
    color: var(--error-red);
  }
}
