@import '../../css/colors.css';

.error-message {
  color: var(--error-red);
  background-color: var(--red-highlight);
  border-radius: 4px;
  box-shadow: 0 0 8px 2px var(--error-red);
  display: inline-block;
  padding: 12px;
  margin-bottom: 20px;

  & .content {
    line-height: 1.3;
    margin: 0;
    display: inline-block;
    min-width: 200px;
    & ul {
      margin: 0;
      padding: 0 0.7rem 0 1.2rem;
    }
  }
}
