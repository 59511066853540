@import '../../css/colors.css';

.pricing-form,
.fulfillment-pricing-form {
  & fieldset {
    margin-right: 1rem;
  }
  & .fieldset-wrapper:last-child fieldset {
    margin: 0;
  }
}

.general-scope-settings {
  & .form-table {
    margin: 56px 0 28px;
    & th,
    & td {
      padding: 0 12px;
    }
  }
}

.fulfillment-group {
  & fieldset {
    padding: 0;
    margin: 0;
  }
  & h4 {
    margin-left: 8px;
  }
}

.pricing-form {
  & .instructions {
    color: var(--blue-med);
    margin-bottom: 30px;
  }
}
