@import '../../css/colors.css';

.locations-page {
  & .edit-mode-chip,
  & .errors-chip {
    & > span {
      display: flex;
      justify-content: space-between;
    }
    & b {
      text-transform: uppercase;
      margin-right: 20px;
    }
  }
  & .errors-chip {
    & a {
      color: var(--red-highlight);
      text-decoration: underline;
    }
  }
  & h1 {
    float: left;
    width: calc(100% - 220px);
    & small {
      font-size: 50%;
    }
  }
  & .edit-quantities-btn,
  & .review-and-save-btn {
    margin-top: 8px;
  }
  & .pagination {
    margin-top: 30px;
    margin-bottom: 10px;
    clear: both;

    & .current-page {
      display: inline-block;
      flex: content;
    }
  }
  & .side-bar {
    & h3 {
      margin: 0;
      marginbottom: 10px;
    }
    & fieldset {
      margin: 0;
    }
    & .filter {
      margin: 50px 0;
    }
  }
}

.pure-table {
  width: 100%;
}

.pull-right {
  float: right;
}

.reason-code-container {
  display: flex;
  flex-direction: column;
}

.reason-code-container > div {
  margin: 5px;
}

.input-layout {
  display: flex;
  flex-direction: row;
}

.edit-description-container {
  width: 550px;
}

.input-layout > div {
  margin: 5px;
}
