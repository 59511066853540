.inventory-groups {
  width: 100%;
  margin-left: 0.75rem;
  margin-bottom: 0.5rem;
  & th {
    text-align: left;
  }
  & td {
    padding: 0.25rem 0.5rem;
    &:first-child {
      width: 40px;
    }
  }
  & .cancel-or-submit-buttons {
    display: inline-block;
  }
}
