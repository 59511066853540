@import '../../css/colors.css';

.bread-crumbs {
  & a.billing-config-anchor {
    float: right;
    cursor: pointer;
  }
}

.reservation-info {
  padding: 20px;
  margin-bottom: 40px;
  & > h3 {
    margin-bottom: 0px;
  }
  & a {
    font-weight: 500;
  }
  & header {
    margin-bottom: 20px;
  }
  & .status {
    color: var(--gray-med-dark);
    float: right;
    & .status-icon {
      margin-right: 4px;
      vertical-align: middle;
    }
    & .text {
      display: inline-block;
      vertical-align: middle;
      border-right: 2px solid var(--gray-lighter);
      padding-right: 1rem;
    }
    & h3 {
      color: var(--gray-dark);
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  & .title {
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
  }
  & .sub-title {
    color: var(--gray-medium);
  }
  & h2 {
    margin-bottom: 0;
  }
  & h3.h4 {
    text-transform: uppercase;
    color: var(--gray-medium);
    border-bottom: 2px solid var(--gray-lighter);
    height: 11px;
    & span {
      background-color: var(--white);
      padding: 0 10px;
    }
  }
  & .scope h3 span {
    padding-left: 0;
  }
  & .approval h4 {
    padding-left: 0;
    margin-bottom: 10px;
  }
  & .bracket {
    font-size: 90px;
    font-weight: 300;
    color: var(--gray-lighter);
  }
  & .vertical-line {
    width: 50%;
    height: 75%;
    margin-top: 50%;
    border-right: 1px solid var(--gray-lighter);
  }
  & .shipper,
  & .warehouse {
    & h3 {
      text-align: center;
    }
  }
  & h4 {
    font-weight: 500;
  }
}

.reservation-detail-page {
  & h3.pricing-heading {
    margin-top: 20px;
    display: inline-block;
  }
  & a.revise-scope {
    display: inline-block;
    vertical-align: 2px;
    margin-left: 1rem;
    & svg {
      vertical-align: -6px;
      margin-right: 4px;
    }
  }
  & span.new-revision {
    font-size: 18px;
    color: var(--gray-med-dark);
    display: inline-block;
    margin-left: 1rem;
  }
  & .pricing-tabs {
    margin-top: 40px;
  }
  & .main-buttons {
    padding: 1.4rem 0;
    border-top: 1px solid var(--gray-lighter);
    margin-top: 14px;
  }
}
